import {
    CreateParams,
    DeleteManyParams,
    DeleteParams,
    fetchUtils,
    GetListParams,
    GetManyParams,
    GetManyReferenceParams,
    GetOneParams,
    UpdateManyParams,
    UpdateParams
} from 'react-admin';
import qs from 'qs';
import {getTokens} from 'utils';

const apiUrl = `${process.env.REACT_APP_API_URL}/admin`;
// const httpClient = fetchUtils.fetchJson;

export const httpClient = (url: string, options = {} as any) => {
    if (!options?.headers) {
        options.headers = new Headers();
    }
    const {accessToken} = getTokens();
    options.headers.set('Authorization', `JWT ${accessToken}`);
    return fetchUtils.fetchJson(url, options);
};

export const dataProvider = {
    getList: (resource: string, params: GetListParams) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        const query = {
            order: {
                by: field,
                direction: order.toLowerCase()
            },
            pagination: {
                take: perPage,
                skip: (page - 1) * perPage
            },
            filter: params.filter
        };
        const url = `${apiUrl}/${resource}?${qs.stringify(query)}`;

        return httpClient(url).then(({json}) => ({data: json.data, total: json.totalCount}));
    },

    getOne: (resource: string, params: GetOneParams) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({json}) => ({data: json})),

    getMany: (resource: string, params: GetManyParams) => {
        const query = {
            filter: {ids: params.ids}
        };
        const url = `${apiUrl}/${resource}?${qs.stringify(query)}`;
        return httpClient(url).then(({json}) => json);
    },

    getManyReference: (resource: string, params: GetManyReferenceParams) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        const query = {
            order: {
                by: field,
                direction: order.toLowerCase()
            },
            pagination: {
                take: perPage,
                skip: (page - 1) * perPage
            },
            filter: {
                ...params.filter,
                [params.target]: params.id
            }
        };
        const url = `${apiUrl}/${resource}?${qs.stringify(query)}`;

        return httpClient(url).then(({json}) => ({
            data: json.data,
            total: json.totalCount
        }));
    },

    create: (resource: string, params: CreateParams) => {
        if (resource === 'media') {
            const formData = new FormData();
            Object.keys(params.data).forEach((key) => {
                if (key === 'file' && params.data.file?.rawFile) {
                    formData.append('uploadedFile', params.data.file.rawFile);
                } else if (params.data[key]) {
                    formData.append(key, params.data[key]);
                }
            });
            return httpClient(`${apiUrl}/${resource}`, {
                method: 'POST',
                body: formData
            }).then(({json}) => ({
                data: {...params.data, id: json.id}
            }));
        }

        if (resource === 'story') {
            const formData = new FormData();
            Object.keys(params.data).forEach((key) => {
                if (key === 'video' && params.data.video?.rawFile) {
                    formData.append('uploadedVideoFile', params.data.video?.rawFile);
                } else if (params.data[key]) {
                    formData.append(key, typeof params.data[key] === 'object' ? JSON.stringify(params.data[key]) : params.data[key]);
                }
            });
            return httpClient(`${apiUrl}/${resource}`, {
                method: 'POST',
                body: formData
            }).then(({json}) => ({
                data: {...params.data, id: json.id}
            }));
        }

        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data)
        }).then(({json}) => ({
            data: {...params.data, id: json.id}
        }));
    },

    update: (resource: string, params: UpdateParams) => {
        if (resource === 'user-request') {
            const formData = new FormData();
            Object.keys(params.data).forEach((key) => {
                if (key === 'file' && params.data.file?.rawFile) {
                    formData.append('uploadedFile', params.data.file?.rawFile);
                } else if (params.data[key]) {
                    formData.append(key, params.data[key]);
                }
            });
            return httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: 'POST',
                body: formData
            }).then(({json}) => ({data: json}));
        }

        if (resource === 'story') {
            const formData = new FormData();
            Object.keys(params.data).forEach((key) => {
                if (key === 'video' && params.data.video?.rawFile) {
                    formData.append('uploadedVideoFile', params.data.video?.rawFile);
                } else if (params.data[key]) {
                    formData.append(key, typeof params.data[key] === 'object' ? JSON.stringify(params.data[key]) : params.data[key]);
                }
            });
            return httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: 'POST',
                body: formData
            }).then(({json}) => ({data: json}));
        }

        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'POST',
            body: JSON.stringify(params.data)
        }).then(({json}) => ({data: json}));
    },

    updateMany: (resource: string, params: UpdateManyParams) => {
        const query = {
            filter: JSON.stringify({id: params.ids})
        };
        return httpClient(`${apiUrl}/${resource}?${qs.stringify(query)}`, {
            method: 'POST',
            body: JSON.stringify(params.data)
        }).then(({json}) => ({data: json}));
    },

    delete: (resource: string, params: DeleteParams) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE'
        }).then(({json}) => ({data: json})),

    deleteMany: (resource: string, params: DeleteManyParams) => {
        const query = {
            ids: params.ids
        };
        return httpClient(`${apiUrl}/${resource}?${qs.stringify(query)}`, {
            method: 'DELETE'
        }).then(({json}) => ({data: json}));
    },
    getJSONStats: (url: string, data: string) => {
        return httpClient(`${apiUrl}/statistics/${url}?${qs.stringify({filter: data})}`, {method: 'GET'}).then(
            ({json}) => json
        );
    },
    getFileStats: (url: string, data: string) => {
        const {accessToken} = getTokens();
        return fetch(`${apiUrl}/statistics/${url}?${qs.stringify({filter: data})}`, {
            headers: {Authorization: 'JWT ' + accessToken},
            method: 'GET'
        }).then((res) => res.blob());
    },
    uploadFile: (url: string, file: any) => {
        const {accessToken} = getTokens();
        const formData = new FormData();
        formData.append('uploadedFile', file.rawFile);
        return fetch(`${apiUrl}/${url}`, {
            headers: {Authorization: 'JWT ' + accessToken},
            method: 'POST',
            body: formData
        }).then((res) => res.blob());
    },
    resetPassword: (id: string) => {
        return httpClient(`${apiUrl}/user/${id}/reset-password`, {method: 'POST'}).then(({json}) => json);
    }
};
